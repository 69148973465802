import React, { Suspense, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header/Header';
import { useSelector } from 'react-redux';
const Form = lazy(() => import('./components/Form/Form'));
const Thanks = lazy(() => import('./components/Thanks/Thanks'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Modal = lazy(() => import('./components/Modal/Modal'));
const OfferEn = lazy(() => import('./components/Policy/OfferEn'));
const OfferUa = lazy(() => import('./components/Policy/OfferUa'));

const Legal = ({ lang }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const modal = useSelector((state) => state.reducer.modal);
  const form = useSelector((state) => state.reducer.form);
  const thanks = useSelector((state) => state.reducer.thanks);
  const body = document.querySelector('body');
  if (modal) {
    body.classList.add('scrollLock');
  } else if (!modal) {
    body.classList.remove('scrollLock');
  }
  return (
    <div className="container">
      <Helmet>
        <title>{lang === 'ua' ? 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ' : 'UIT EN'}</title>
        <meta
          name="description"
          content={lang === 'ua' ? 'INVEST TEAM UA' : 'INVEST TEAM EN'}
        />
      </Helmet>
      <Header lang={lang} policy={true} />
      <Suspense fallback={<div>Loading...</div>}>
        {lang === 'en' ? <OfferEn /> : <OfferUa />}
        <Footer lang={lang} policy={true} />
        {modal && (
          <Modal lang={lang}>
            {form && <Form lang={lang} />}
            {thanks && <Thanks lang={lang} />}
          </Modal>
        )}
      </Suspense>
    </div>
  );
};

export default Legal;
