import React from 'react';

const AboutUs = ({ lang }) => {
  return (
    <section id="about" className="section about-us">
      {lang === 'en' ? (
        <h3 className="title about-us-title">
          <b>about</b> us
        </h3>
      ) : (
        <h3 className="title about-us-title">
          <b>Про</b> нас
        </h3>
      )}

      <div className="about-us-content">
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about1.svg"
            alt="icon"
          />

          <p className="title about-us-card-title">
            {lang === 'en' ? '10+ lectures per year' : '10+ лекцій в рік'}
          </p>
          <p className="text about-us-card-txt">
            {lang === 'en'
              ? 'We offer more than 10 lectures every year from renowned experts who have extensive practical experience in the fields of investment and entrepreneurship.'
              : 'Ми пропонуємо понад 10 лекцій щороку від відомих експертів, які мають широкий практичний досвід у сферах інвестування та підприємництва.'}
          </p>
        </div>
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about2.svg"
            alt="icon"
          />
          <p className="title about-us-card-title">
            {lang === 'en' ? 'Basic training' : 'Базове навчання '}
          </p>
          <p className="text about-us-card-txt">
            {' '}
            {lang === 'en'
              ? 'Including videos and tutorials on the most profitable tools so that even beginners can understand every step in any topic.'
              : 'Включає відео та покрокові інструкції про найбільш прибуткові інструменти, які ми використовуємо, щоб навіть початківці змогли розібратись з повного нуля.'}
          </p>
        </div>
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about3.svg"
            alt="icon"
          />
          <p className="title about-us-card-title">
            {lang === 'en' ? 'Community of leaders' : 'Ком’юніті лідерів'}
          </p>
          <p className="text about-us-card-txt">
            {' '}
            {lang === 'en'
              ? 'We are building a community of leaders who motivate and provide advice on developing key investor skills, effectively enhancing your personal brand, and achieving a leadership position.'
              : 'Ми будуємо ком’юніті лідерів, які мотивують та надають поради щодо розвитку ключових навичок інвестора, ефективного підвищення свого особистого бренду та досягнення лідерської позиції.'}
          </p>
        </div>
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about4.svg"
            alt="icon"
          />
          <p className="title about-us-card-title">
            {lang === 'en'
              ? 'Joint financial projects'
              : 'Спільні фінансові проєкти'}
          </p>
          <p className="text about-us-card-txt">
            {' '}
            {lang === 'en'
              ? "The opportunity to join the club's joint financial projects, including crypto startups, real estate, real business, the stock market and other investment tools."
              : 'Можливість приєднатися до спільних фінансових проєктів клубу, включаючи інвестиції в криптостартапи, нерухомість, реальний бізнес, фондовий ринок та інші інструменти для інвестування.'}
          </p>
        </div>
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about5.svg"
            alt="icon"
          />
          <p className="title about-us-card-title">
            {lang === 'en' ? 'Interest clubs' : 'Клуби за інтересами'}
          </p>
          <p className="text about-us-card-txt">
            {' '}
            {lang === 'en'
              ? 'Additionally, we have interest clubs that welcome the diversity of our members. For example, if you like poker, we have a club where you can play with like-minded people.'
              : 'В рамках нашої спільноти є клуби за інтересами. Наприклад, якщо ви любите покер, у нас є клуб, де ви зможете зіграти з однодумцями.'}
          </p>
        </div>
        <div className="about-us-card">
          <img
            loading="lazy"
            width={49}
            height={49}
            src="./assets/about6.svg"
            alt="icon"
          />
          <p className="title about-us-card-title">
            {lang === 'en' ? 'Active leisure time' : 'Активний відпочинок'}
          </p>
          <p className="text about-us-card-txt about-us-card-txt-last">
            {' '}
            {lang === 'en'
              ? 'Our club is not only about money and investments. We also organize meetings in different cities, so that our participants have the opportunity to spend quality time, exchange experiences, discover new hobbies and simply communicate in a live format.'
              : 'Наш клуб — це не лише про гроші та інвестиції. Ми також організовуємо зустрічі в різних містах, щоб наші учасники мали можливість якісно провести час, обмінюватися досвідом, відкривати нові хобі та просто спілкуватися наживо.'}
          </p>
        </div>
      </div>
      <div className="about-us-blur1"></div>
      <div className="about-us-blur2"></div>
      <div className="about-us-blur3"></div>
    </section>
  );
};

export default AboutUs;
