import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Homepage from './Homepage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { store } from './store/store';
import { Provider } from 'react-redux';
import Policy from './Policy';
import Legal from './Legal';

const userLanguage = navigator.language || navigator.userLanguage;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage userLanguage={userLanguage} lang={'ua'} />,
  },
  {
    path: '/en',
    element: <Homepage userLanguage={userLanguage} lang={'en'} />,
  },
  {
    path: '/policy',
    element: <Policy lang={'en'} />,
  },
  {
    path: '/privacy',
    element: <Policy lang={'ua'} />,
  },
  {
    path: '/offer',
    element: <Legal lang={'en'} />,
  },
  {
    path: '/legal',
    element: <Legal lang={'ua'} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
