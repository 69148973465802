import React from 'react';

const HeroBottomRight3 = ({ lang }) => {
  return (
    <div className="hero-bottom-desk-right3">
      <img
        loading="lazy"
        width={22}
        height={22}
        src="./assets/hero-star.svg"
        alt="star"
      />
      {lang === 'en' ? (
        <p>
          Access to the <b>most profitable</b>
          <br /> club deals
        </p>
      ) : (
        <p>
          Доступ до <b>найвигідніших</b>
          <br /> клубних угод
        </p>
      )}
    </div>
  );
};

export default HeroBottomRight3;
