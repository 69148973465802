import React from 'react';
import { useDispatch } from 'react-redux';
import { setBurger } from '../../store/slice';
import { Link } from 'react-scroll';

const HeaderNav = ({ lang, locale, policy }) => {
  const dispatch = useDispatch();
  const handleNav = () => {
    if (locale === 'inBurger') {
      dispatch(setBurger(false));
      document.querySelector('body').style.overflow = 'auto';
    }
  };

  return (
    <nav>
      <ul className={`header__nav ${locale} ${policy === true ? 'hide' : ''}`}>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="mission"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Our mission' : 'Наша місія'}
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="members"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Participants' : 'Учасники'}
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="mentor"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Club mentor' : 'Ментор клубу'}
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="plans"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Plans' : 'Плани'}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNav;
