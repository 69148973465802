import React, { Suspense, lazy, useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Hero from './components/SectionHero/Hero';
import { Helmet } from 'react-helmet';
import AboutUs from './components/SectionAboutUs/AboutUs';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
const Form = lazy(() => import('./components/Form/Form'));
const Thanks = lazy(() => import('./components/Thanks/Thanks'));
const Strategies = lazy(() =>
  import('./components/SectionStrategies/Strategies')
);
const Members = lazy(() => import('./components/SectionMembers/Members'));
const Mentor = lazy(() => import('./components/SectionMentor/Mentor'));
const Plans = lazy(() => import('./components/SectionPlans/Plans'));
const Tariff = lazy(() => import('./components/SectionTariff/Tariff'));
const Participants = lazy(() =>
  import('./components/SectionParticipants/Participants')
);
const Footer = lazy(() => import('./components/Footer/Footer'));
const Modal = lazy(() => import('./components/Modal/Modal'));

const Homepage = ({ lang, userLanguage }) => {
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get('ref');
    if (myParam) {
      localStorage.setItem('ref', myParam);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  document
    .querySelector('html')
    .setAttribute('lang', lang === 'ua' ? 'uk' : 'en');

  if (lang === 'ua') {
    document.querySelector('meta[name="description"]').content =
      'Приєднуйтеся до UNITED INVEST TEAM і отримайте знання, які розкриють перед вами світ інвестицій та фінансової незалежності.';
  }
  if (lang === 'en') {
    document.querySelector('meta[name="description"]').content =
      'Join UNITED INVEST TEAM and gain the knowledge that will open up the world of investments and financial independence to you.';
  }

  const navigate = useNavigate();

  const [lazyValue, setLazyValue] = useState(false);

  useEffect(() => {
    if (
      userLanguage === 'uk' ||
      userLanguage === 'ru' ||
      userLanguage === 'ru-RU' ||
      userLanguage === 'uk-UA'
    ) {
      navigate('/');
    } else {
      navigate('/en');
    }
    setTimeout(() => {
      setLazyValue(true);
    }, 1000);
  }, []);

  const modal = useSelector((state) => state.reducer.modal);
  const form = useSelector((state) => state.reducer.form);
  const thanks = useSelector((state) => state.reducer.thanks);
  const body = document.querySelector('body');
  if (modal) {
    body.classList.add('scrollLock');
  } else if (!modal) {
    body.classList.remove('scrollLock');
  }
  return (
    <div className="container">
      <Helmet>
        <title>
          {lang === 'ua'
            ? 'UNITED INVEST TEAM: Розширте свій інвестиційний потенціал'
            : 'UNITED INVEST TEAM: Expand Your Investment Potential'}
        </title>
      </Helmet>
      <Header lang={lang} />
      <Hero lang={lang} />
      <AboutUs lang={lang} />
      {lazyValue && (
        <Suspense fallback={<div>Loading...</div>}>
          <Strategies lang={lang} />
          <Members lang={lang} />
          <Mentor lang={lang} />
          <div className="last-sections">
            <Plans lang={lang} />
            <Tariff lang={lang} />
            <Participants lang={lang} />
          </div>
          <Footer lang={lang} />
          {modal && (
            <Modal lang={lang}>
              {form && <Form lang={lang} />}
              {thanks && <Thanks lang={lang} />}
            </Modal>
          )}
        </Suspense>
      )}
    </div>
  );
};

export default Homepage;
