import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: false,
  form: false,
  thanks: false,
  burger: false,
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
    setThanks: (state, action) => {
      state.thanks = action.payload;
    },
    setBurger: (state, action) => {
      state.burger = action.payload;
    },
  },
});

export const { setModal, setForm, setThanks, setBurger } = slice.actions;
export default slice.reducer;
