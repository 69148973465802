import React from 'react';
import { useDispatch } from 'react-redux';
import { setForm, setModal } from '../../store/slice';

const SectionsBtn = ({ mod, lang }) => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setModal(true));
    dispatch(setForm(true));
  };
  return (
    <button
      onClick={handleModalOpen}
      className={`section-btn ${mod ? mod : ''}`}
    >
      {lang === 'en' ? 'Join us' : 'Приєднатися'}
      <img loading="lazy" src="./assets/btn-arrow.svg" alt="btn" />
    </button>
  );
};

export default SectionsBtn;
