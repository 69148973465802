import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setForm, setModal } from '../../store/slice';

const HeaderRight = ({ lang, locale }) => {
  const [langSelect, setLangSelect] = useState('hide');
  const handleDropdown = () => {
    setLangSelect((prev) => (prev === 'hide' ? 'active' : 'hide'));
  };
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setModal(true));
    dispatch(setForm(true));
  };
  return (
    <div className={`header__right ${locale}`}>
      <div className="header__right-lang" onClick={handleDropdown}>
        {lang === 'en' ? 'En' : 'Ua'}
        <img
          className={langSelect === 'active' ? 'up-down' : ''}
          width={8}
          height={8}
          src="./assets/lang-arrow.svg"
          alt="lang"
        />
        <Link
          to={lang === 'en' ? '/' : '/en'}
          className={`header__lang-options ${langSelect}`}
        >
          {lang === 'en' ? 'Ua' : 'En'}
        </Link>
      </div>
      <button onClick={handleModalOpen}>
        {lang === 'en' ? 'Join us' : 'Приєднатися'}
      </button>
    </div>
  );
};

export default HeaderRight;
