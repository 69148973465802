import React from 'react';
import { Link } from 'react-scroll';

const HeroBottomCenter = ({ lang }) => {
  return (
    <div className="hero-bottom-desk-center">
      <img
        loading="lazy"
        width={200}
        height={157}
        src="./assets/hero-img-about.webp"
        alt="img"
      />
      <div>
        {lang === 'en' ? (
          <p>
            No hype tools and "shady schemes". We are about the development of
            investment thinking and the growth of income based on learning
            various investment tools.
          </p>
        ) : (
          <p>
            Жодних хайпових інструментів та “мутних тем”. Ми — про розвиток
            інвест-мислення та ріст доходів завдяки навчанню різним
            інвестиційним інструментам.
          </p>
        )}
        <Link to="about" spy={true} smooth={false} duration={500} offset={-50}>
          {lang === 'en' ? 'About us' : 'Про нас'}{' '}
          <img src="./assets/arrow-right.svg" alt="arrow" />
        </Link>
      </div>
    </div>
  );
};

export default HeroBottomCenter;
