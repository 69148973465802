import React from 'react';
import HeaderRight from './HeaderRight';
import HeaderNav from './HeaderNav';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBurger } from '../../store/slice';

const Header = ({ lang, policy }) => {
  const dispatch = useDispatch();
  const burger = useSelector((state) => state.reducer.burger);
  const handleBurgerOpen = () => {
    const header = document.querySelector('.header');
    const body = document.querySelector('body');
    dispatch(setBurger(burger ? false : true));
    header.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
    if (body.style.overflow === 'hidden') {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  window.addEventListener('scroll', function () {
    const header = document.querySelector('.header');
    if (window.pageYOffset > 0) {
      if (header.style.backgroundColor !== 'rgba(0, 0, 0, 0.8)') {
        header.style.backdropFilter = 'blur(10px)';
        header.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      }
    } else {
      if (header.style.backgroundColor !== 'transparent') {
        header.style.backdropFilter = 'blur(0px)';
        header.style.backgroundColor = 'transparent';
      }
    }
  });

  return (
    <header className="header">
      <div style={{ overflow: 'visible' }} className="container header-inner">
        <Link className="header__logo" to={lang === 'en' ? '/en' : '/'}>
          <img
            width={198}
            height={42}
            src="./assets/header-logo.svg"
            alt="logo"
          />
        </Link>
        <HeaderNav policy={policy} locale="inHeader" lang={lang} />
        <HeaderRight locale="inHeader" lang={lang} />
        <div onClick={handleBurgerOpen} className="header__burger-btn">
          <img
            width={36}
            height={36}
            src={burger ? './assets/burger-close.svg' : './assets/burger.svg'}
            alt="burger"
          />
        </div>
        {burger ? <BurgerMenu lang={lang} /> : ''}
      </div>
    </header>
  );
};

export default Header;
