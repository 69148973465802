import React from 'react';

const HeroBottomRight2 = ({ lang }) => {
  return (
    <div className="hero-bottom-desk-right2">
      <img
        loading="lazy"
        width={22}
        height={22}
        src="./assets/hero-star.svg"
        alt="star"
      />
      {lang === 'en' ? (
        <p>
          Lifestyle, constant development and <b>vibrant leisure</b>
        </p>
      ) : (
        <p>
          Lifestyle,&nbsp;постійний розвиток&nbsp;та{' '}
          <b>яскравий&nbsp;відпочинок</b>
        </p>
      )}
    </div>
  );
};

export default HeroBottomRight2;
