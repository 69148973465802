import React from 'react';
import HeaderRight from '../Header/HeaderRight';
import HeaderNav from '../Header/HeaderNav';
import Socials from '../Socials/Socials';

const BurgerMenu = ({ lang }) => {
  return (
    <div className="burger-menu">
      <HeaderRight locale="inBurger" lang={lang} />
      <HeaderNav locale="inBurger" lang={lang} />
      <Socials />
    </div>
  );
};

export default BurgerMenu;
