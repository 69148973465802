import React from 'react';

const HeroBottomLeft = ({ lang }) => {
  return (
    <div className="hero-bottom-desk-left">
      {lang === 'en' ? (
        <p className="hero-bottom-desk-left-title-top">
          Strong&nbsp;community&nbsp;of
        </p>
      ) : (
        <p className="hero-bottom-desk-left-title-top">
          Сильне&nbsp;оточення&nbsp;з
        </p>
      )}
      {lang === 'en' ? (
        <p className="hero-bottom-desk-left-title-bottom">
          <b>700+</b>residents
        </p>
      ) : (
        <p className="hero-bottom-desk-left-title-bottom">
          <b>700+</b>резидентів
        </p>
      )}

      <img
        loading="lazy"
        width={210}
        height={80}
        src="./assets/hero-residents.webp"
        alt="residents"
      />
    </div>
  );
};

export default HeroBottomLeft;
