import React from 'react';

const Socials = () => {
  return (
    <div className="socials">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://youtube.com/@UITCommunity"
      >
        <img
          loading="lazy"
          width={44}
          height={44}
          src="./assets/yt.svg"
          alt="inst"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/uit.community"
      >
        <img
          loading="lazy"
          width={44}
          height={44}
          src="./assets/inst.svg"
          alt="inst"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.tiktok.com/@united_invest_team"
      >
        <img
          loading="lazy"
          width={44}
          height={44}
          src="./assets/tiktok.svg"
          alt="tiktok"
        />
      </a>
    </div>
  );
};

export default Socials;
