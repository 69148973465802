import React from 'react';
import SectionsBtn from '../ui/SectionsBtn';
import HeroBottomLeft from './HeroBottomLeft';
import HeroBottomCenter from './HeroBottomCenter';
import HeroBottomRight1 from './HeroBottomRight1';
import HeroBottomRight3 from './HeroBottomRight3';
import HeroBottomRight2 from './HeroBottomRight2';

const Hero = ({ lang }) => {
  return (
    <section className="section hero">
      {lang === 'en' ? (
        <h1 className="title hero-title">
          A powerful
          <br className="hero-title-br" />
          <span className="hero-title-icon">
            <img
              loading="lazy"
              width={100}
              height={85}
              src="./assets/hero-title-icon-2.svg"
              alt="title icon"
            />
          </span>
          ecosystem
          <br />
          <b>for&nbsp;investor’s growth</b>
        </h1>
      ) : (
        <h1 className="title hero-title">
          потужна
          <br className="hero-title-br" />
          <span className="hero-title-icon">
            <img
              loading="lazy"
              width={100}
              height={85}
              src="./assets/hero-title-icon-2.svg"
              alt="title icon"
            />
          </span>
          екосистема
          <br />
          <b>для розвитку інвестора</b>
        </h1>
      )}
      {lang === 'en' ? (
        <p className="text hero-subtitle">
          An educational platform for investors and entrepreneurs who seek
          financial independence and growth in the field of investments
        </p>
      ) : (
        <p className="text hero-subtitle">
          Освітня платформа для інвесторів та підприємців, які прагнуть
          фінансової незалежності та розвиваються у сфері інвестицій
        </p>
      )}

      <SectionsBtn lang={lang} mod="hero-btn" />
      <div className="hero-bottom-desk">
        <HeroBottomLeft lang={lang} />
        <HeroBottomCenter lang={lang} />
        <div className="hero-bottom-desk-right">
          <HeroBottomRight1 lang={lang} />
          <HeroBottomRight2 lang={lang} />
          <HeroBottomRight3 lang={lang} />
        </div>
      </div>
      <div className="hero-bottom-mob">
        <div className="hero-bottom-mob-left">
          <HeroBottomLeft lang={lang} />
          <HeroBottomRight2 lang={lang} />
          <HeroBottomRight1 lang={lang} />
        </div>
        <div className="hero-bottom-mob-right">
          <HeroBottomCenter lang={lang} />
          <HeroBottomRight3 lang={lang} />
        </div>
      </div>
      <div className="hero-blur1"></div>
      <div className="hero-blur2"></div>
      <div className="hero-blur3"></div>
      <div className="hero-blur4"></div>
      <div className="hero-blur5"></div>
      <div className="hero-blur6"></div>
      <div className="hero-blur7"></div>
      <div className="hero-cristal">
        <img loading="lazy" src="./assets/about-img.webp" alt="cristal" />
      </div>
    </section>
  );
};

export default Hero;
