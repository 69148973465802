import React from 'react';

const HeroBottomRight1 = ({ lang }) => {
  return (
    <div className="hero-bottom-desk-right1">
      <img
        loading="lazy"
        width={22}
        height={22}
        src="./assets/hero-star.svg"
        alt="star"
      />
      {lang === 'en' ? (
        <p>
          Online and offline events with <b>famous speakers</b>
        </p>
      ) : (
        <p>
          Онлайн&nbsp;та&nbsp;оффлайн події з&nbsp;<b>відомими спікерами</b>
        </p>
      )}
    </div>
  );
};

export default HeroBottomRight1;
